import { createRouter, createWebHistory } from "vue-router";

// libs

// import * as _api from '../lib/api'
import Boot from "../views/Boot.vue";

const routes = [
	{
		path: "/",
		name: "boot",
		component: Boot,
		props: true,

		children: [
			{
				path: "/",
				name: "home",
				component: () => import("../views/Home.vue"),
			},
			{
				path: "/service/seal",
				name: "seal",
				component: () => import("../views/service/Seal.vue"),
			},
			{
				path: "/service/strap-care",
				name: "strap-care",
				component: () => import("../views/service/StrapCare.vue"),
			},
			{
				path: "/service/herritage-commitment",
				name: "commitment",
				component: () => import("../views/service/Commitment.vue"),
			},
			{
				path: "/history/jaquessermand",
				name: "history",
				component: () => import("../views/history/History.vue"),
			},
			// {
			// 	path: "/history/admiration",
			// 	name: "admiration",
			// 	component: () => import("../views/history/Admiration.vue"),
			// },
			{
				path: "/history/museums",
				name: "museums",
				component: () => import("../views/history/Museums.vue"),
			},
			{
				path: "/history/watches",
				name: "watches",
				component: () => import("../views/history/Watches.vue"),
			},
			{
				path: "/innovation/animated-nft",
				name: "animatedNft",
				component: () => import("../views/innovation/AnimatedNft.vue"),
			},
			{
				path: "/innovation/marcel-van-luit",
				name: "creator",
				component: () => import("../views/innovation/Creator.vue"),
			},
			{
				path: "/innovation/digital-ownership",
				name: "digital-ownership",
				component: () => import("../views/innovation/DigitalOwnership.vue"),
			},
			{
				path: "/watch/story",
				name: "watchstory",
				component: () => import("../views/history/Admiration.vue"),
			},
			{
				path: "/watch/details",
				name: "details",
				component: () => import("../views/watch/Details.vue"),
			},
			{
				path: "/watch/specifications",
				name: "watch",
				component: () => import("../views/watch/Watch.vue"),
			},
			// {
			// 	path: "/nft",
			// 	name: "nft",
			// 	component: () => import("../views/Nft.vue"),
			// },
			// {
			// 	path: "/construct-watch",
			// 	name: "construct",
			// 	component: () => import("../views/ConstructWatch.vue"),
			// },
			// {
			// 	path: "/roadmap",
			// 	name: "roadmap",
			// 	component: () => import("../views/Roadmap.vue"),
			// },
			{
				path: "/terms-of-use",
				name: "termsofuse",
				component: () => import("../views/TermsOfUse.vue"),
			},
			{
				path: "/privacy-policy",
				name: "privacypolicy",
				component: () => import("../views/PrivacyPolicy.vue"),
			},
			{
				path: "/blog/:slug",
				name: "blog",
				props: true,
				component: () => import("../views/BlogPage.vue"),
			},
			// {
			//     path: '/whitelist',
			//     name: 'whitelist',
			//     component: () => import('../views/Whitelist.vue'),
			// },
			// {
			//     path: '/whitelist/confirmation',
			//     name: 'whitelistconfirmation',
			//     component: () => import('../views/WhitelistConfirmation.vue'),
			// },
			{
				path: "/checkout",
				name: "checkout",
				component: () => import("../views/Checkout.vue"),
			},
			{
				path: "/confirmation",
				name: "confirmation",
				component: () => import("../views/Confirmation.vue"),
			},
			{
				path: "/:pathMatch(.*)",
				name: "404",
				component: () => import("../views/404.vue"),
			},
		],
	},
];

const router = createRouter({
	scrollBehavior() {
		return { left: 0, top: 0 };
	},
	history: createWebHistory(),
	routes,
});

router.onError((error) => {
	if (/ChunkLoadError:.*failed./i.test(error.message) || /Loading.*chunk.*failed./i.test(error.message)) {
		window.location.reload();
	}
});

export default router;
